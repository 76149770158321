import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesomePaginate from "vue-awesome-paginate";
import './assets/css/public.less'   //公共css
import methods from './methods/methods.js'
import "vue-awesome-paginate/dist/style.css";

const app = createApp(App)
app.use(VueAwesomePaginate)
app.config.globalProperties.$methods = methods
app.use(store).use(router).mount('#app')
