<template>
    <div class="wrapper_main" ref="scrollbar">
        <headerPage @searchFun="searchFun"></headerPage>
        <router-view v-slot="{ Component }">
            <component ref="main" :is="Component" />
        </router-view>
        <footerPage></footerPage>

    </div>
</template>

<script>
    import headerPage from '@/components/headerPage.vue'
    import footerPage from '@/components/footerPage.vue'
    import {
        getConfig
    } from '@/api/index'
    export default {
        components: {
            headerPage,
            footerPage,
        },
        data() {
            return {
                timer: null
            }
        },
        created() {
            this.getConfig()
        },
        mounted() {
            // window.addEventListener('scroll', this.handerScroll)
        },
        unmounted() {
            // window.removeEventListener('scroll', this.handerScroll)
            // clearTimeout(this.timer)
        },
        methods: {
            handerScroll() {
                let scrollTop = document.documentElement.scrollTop
                let scrollHeight = document.documentElement.scrollHeight
                let clientHeight = document.documentElement.clientHeight
                if ((scrollHeight - clientHeight) <= scrollTop) {
                    //滚动条滚到底部
                    if (this.$refs.main.pagesFun) {
                        clearTimeout(this.timer)
                        this.timer = setTimeout(() => {
                            this.$refs.main.pagesFun()
                        }, 300)
                    }
                }
            },
            getConfig() {
                getConfig().then(res => {
                    localStorage.setItem('title', res.title)
                    this.$methods.setHeadData(res)
                })
            },
            searchFun(keyword){
                this.$refs.main.searchFun(keyword)
            }
        },
    };
</script>

<style lang="less" scoped>
    .wrapper_main {
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
    }
</style>