<template>
    <div class="container">
        <bannerPage :listData="listData"></bannerPage>

        <subjectPage subjectBg="subject_index_bg_1">
            <div class="pb20">
                <div class="type_main">
                    <div class="type_list type_box" :key="index" @click="typeActive(item.id,item.pid,index)"
                        :class="item.id == typeActivity ? 'type_activity' : ''" v-for="(item,index) in typeList">
                        <span class="cursors title_text_hover text_bold">{{ item.title }}</span>
                    </div>
                </div>
                <swiper ref="swiper" @swiper="onSwiper" class="mySwiper" :autoplay="autoplayData" :loop="true"
                    @slideChange="slideChange">
                    <swiper-slide v-for="(data,index) in typeList" :key="index">
                        <div class="region_main region_main_a" @mouseover="stopSwiper" @mouseout="startSwiper">
                            <template v-for="(item,ss) in data.article" :key="ss">
                                <div class="region_box pb20" v-if="ss<4" @click="tapInfo(item.id,item.pid)">
                                    <div class="contents hoverClass">
                                        <div class="line"></div>
                                        <div class="title ellipsis title_text_hover">{{item.title}}</div>
                                        <div class="content ellipsis" :class="{content_a:data.id == 11}">
                                            {{item.description}}</div>
                                        <div class="times">{{item.publish_time}}</div>
                                        <div class="imgs" :id="'imgs' + item.id" v-if="item.thumb"
                                            :style="settingHeight('#imgs' + item.id)">
                                            <img :src="item.thumb" />
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="button_box tc">
                            <button class="buttons cursors" @click="handleJump(typeActivity,typePid)">了解更多</button>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="region_main region_main_a phone_box">
                    <div class="region_box pb20" v-for="(item,ss) in pbList" :key="ss"
                        @click="tapInfo(item.id,item.pid)">
                        <div class="contents hoverClass">
                            <div class="line"></div>
                            <div class="title ellipsis title_text_hover">{{item.title}}</div>
                            <div class="content ellipsis">{{item.description}}</div>
                            <div class="times">{{item.publish_time}}</div>
                            <div class="imgs" :id="'imgs' + item.id" v-if="item.thumb"
                                :style="settingHeight('#imgs' + item.id)">
                                <img :src="item.thumb" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="button_box tc phone_box">
                    <button class="buttons cursors" @click="handleJump(typeActivity,typePid)">了解更多</button>
                </div>
            </div>
        </subjectPage>

        <subjectPage>
            <div>
                <div class="type_main">
                    <div class="type_list text_bold">集团五大农业产业板块</div>
                </div>
                <div>
                    <div class="introduction clearfix">
                        <template v-for="(item,index) in pbTwoList" :key="index">
                            <div @click="tapInfo(item.id,item.pid,item.category_id)"
                                :class="index == 2 ? 'floatr' : index == 3 || index == 4 ? 'floatl floatla clearfix' : 'floatl'"
                                v-if="index < 5 && (index == 3 || index == 4)">
                                <div class="brief_r title_text_hover">
                                    <div class="brief_title">{{item.title}}</div>
                                    <div class="brief_line"></div>
                                    <div class="brief_content ellipsis">
                                        {{item.description}}
                                    </div>
                                    <!--  @click.stop="handleJump(item.category_id,item.pid)" -->
                                    <div class="mores cursors">
                                        查看更多
                                        <img src="@/assets/images/public/icon_more.png" />
                                    </div>
                                    <div class="sanjiao"></div>
                                </div>
                                <div class="brief_l" v-if="item.thumb" :id="'brief_l' + item.id"
                                    :style="settingHeight('#brief_l' + item.id)">
                                    <img :src="item.thumb" />
                                </div>
                            </div>
                            <div @click="tapInfo(item.id,item.pid,item.category_id)"
                                :class="index == 2 ? 'floatr' : index == 3 || index == 4 ? 'floatl floatla clearfix' : 'floatl'"
                                v-else-if="index < 5 && (index != 3 || index != 4)">
                                <div class="brief_l" v-if="item.thumb" :id="'brief_l' + item.id"
                                    :style="settingHeight('#brief_l' + item.id)">
                                    <img :src="item.thumb" />
                                </div>
                                <div class="brief_r title_text_hover">
                                    <div class="brief_title">{{item.title}}</div>
                                    <div class="brief_line"></div>
                                    <div class="brief_content ellipsis">
                                        {{item.description}}
                                    </div>
                                    <!--  @click.stop="handleJump(item.category_id,item.pid)" -->
                                    <div class="mores cursors">
                                        查看更多
                                        <img src="@/assets/images/public/icon_more.png" />
                                    </div>
                                    <div class="sanjiao two_sanjiao" v-if="index == 2"></div>
                                    <div class="sanjiao one_sanjiao" v-else></div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </subjectPage>
        <subjectPage subjectBg="subject_index_bg_4" class="s_i_g_4">
            <div>
                <div class="type_main">
                    <div class="type_list text_bold">{{pbFiveList.title}}</div>
                    <div class="more_main">
                        <div class="more_text title_text_hover" @click="handleJump(pbFiveList.id,pbFiveList.pid)">更多
                            +
                        </div>
                    </div>
                </div>
                <div class="brand_main clearfix">
                    <div class="brand_box hoverClass" @click="tapInfo(item.id,pbFiveList.pid,item.category_id)"
                        v-for="(item,index) in pbFiveList.article" :key="index">
                        <div class="content_box">
                            <div v-if="item.thumb" :id="'xx' + item.id" :style="settingHeight('#xx' + item.id)">
                                <img :src="item.thumb" />
                            </div>
                            <div class="brand_content">
                                <div class="brand_title ellipsis">{{item.title}}</div>
                                <div class="brand_text ellipsis">{{item.description}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </subjectPage>
        <subjectPage subjectBg="subject_index_bg_2">
            <div class="pb30">
                <div class="type_main">
                    <div class="type_list" @click="typeActiveThere(item.id)"
                        :class="item.id == classActivity ? 'type_activity' : ''" v-for="(item,index) in classList"
                        :key="index">
                        <span class="cursors title_text_hover text_bold">{{ item.title }}</span>
                    </div>
                </div>
                <div class="class_main">
                    <div class="class_box" @click="tapInfo(item.id,pbThereList.pid)" v-for="(item,index) in pbThereList.article" :key="index">
                        <div class="class_contents hoverClass">
                            <div class="left" v-if="item.thumb" :id="'left' + item.id"
                                :style="settingHeight('#left' + item.id)">
                                <img :src="item.thumb" />
                            </div>
                            <div class="right">
                                <div class="title ellipsis title_text_hover">{{item.pid}}-{{item.title}}</div>
                                <div class="text ellipsis">{{item.description}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pb30" v-if="configInfo.report">
                    <div class="jubao_box cursors" @click="tapAinfo">
                        <img src="@/assets/images/public/icon_jubao.png" />举报入口
                    </div>
                </div>
            </div>
        </subjectPage>

        <subjectPage subjectBg="subject_index_bg_3">
            <div class="special_subject">
                <div class="type_main">
                    <div class="type_list text_bold">热点专题</div>
                </div>
                <div class="region_main hot_topics_img" v-for="(data,index) in pbFoureList" :key="index">
                    <div class="region_box hoverClass cursors" @click="openUrl(data.jumplink)">
                        <img :src="data.image" style="vertical-align: middle;"/>
                    </div>
                </div>
                <!-- <div class="region_main hot_topics_text" v-if="pbFoureList.article.length">
                    <div class="region_box" v-for="(item,index) in pbFoureList.article" :key="index">
                        <div class="topics_img"><img src="@/assets/images/public/icon_arrow.png" /></div>
                        <div class="topics_text">{{item.title}}</div>
                    </div>
                </div> -->
            </div>
        </subjectPage>

    </div>
</template>

<script>
    import bannerPage from '@/components/bannerPage.vue'
    import subjectPage from '@/components/subjectPage.vue'
    import {
        getCategory,
        getBanner,
        getConfig
    } from '@/api/index.js'
    //轮播
    import {
        Swiper,
        SwiperSlide
    } from 'swiper/vue';
    import 'swiper/css';
    import SwiperCore, {
        Autoplay
    } from "swiper";
    SwiperCore.use([Autoplay]);
    import config from '@/methods/config'
    export default {
        components: {
            bannerPage,
            subjectPage,
            Swiper,
            SwiperSlide,
        },
        data() {
            return {
                swiper: {},
                autoplayData: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                // bannerExplain:'打造全省现代农业发展龙头企业，<br />争创全国一流农业综合性产业集团',
                listData: [],

                typeList: [{
                        title: '集团要闻',
                        id: '1'
                    },
                    {
                        title: '所属企业动态',
                        id: '2'
                    },
                    {
                        title: '媒体聚焦',
                        id: '3'
                    },
                    {
                        title: '政策法规',
                        id: '4'
                    }
                ],
                typeActivity: 1,
                pbList: [],
                pbTwoList: [],
                classList: [{
                        title: '党建工作',
                        id: '1'
                    },
                    {
                        title: '党风廉政',
                        id: '2'
                    },
                    {
                        title: '农发风采',
                        id: '3'
                    }
                ],
                classActivity: 1,
                pbThereList: {
                    article:[]
                },
                pbFoureList: [],
                pbFiveList: {},
                id: null,
                typePid: null,
                configInfo: {}
            }
        },
        created() {
            this.id = this.$route.query.id
            this.getInfoWen()
            this.getBanner()
            this.getInfoWenTwo()
            this.getInfoWenThere()
            this.getInfoWenFoure()
            this.getInfoWenFive()
            this.getConfig()
        },
        mounted() {

        },
        methods: {
            jumpUrl(url) {
                let a = document.createElement('a')
                a.href = config.url + url + '?id=' + this.pbFoureList.id
                a.target = '_blank'
                a.click()
            },
            openUrl(url) {
                let loca = window.location
                let pageUrl = loca.protocol + '//' + loca.host + url
                window.open(pageUrl, '_blank');
            },
            getConfig() {
                getConfig().then(res => {
                    this.configInfo = res
                })
            },
            getBanner() {
                getBanner().then(res => {
                    this.listData = res.map(item => {
                        return {
                            title: item.description,
                            imgUrl: item.url,
                            jumplink: item.jumplink
                        }
                    })
                })
            },
            getInfoWen() {
                getCategory({
                    data: {
                        is_recommend: 1,
                        limit:4
                    }
                }).then(res => {
                    res.forEach(item => {
                        if (item.article.length) {
                            item.article.forEach(data => {
                                data.pid = item.pid
                            })
                        }
                    })
                    if (res && res[0]) {
                        this.typeActivity = res[0].id
                        this.typePid = res[0].pid
                        this.typeList = res
                        this.pbList = res[0].article
                    }

                })
            },
            getInfoWenTwo() {
                getCategory({
                    data: {
                        is_recommend: 2,
                        limit:5
                    }
                }).then(res => {
                    let arr = []
                    res.forEach(item => {
                        if (item.article.length) {
                            item.article.forEach(data => {
                                arr.push({
                                    ...data,
                                    pid: item.pid
                                })
                            })
                        }
                    })
                    this.pbTwoList = arr
                })
            },
            getInfoWenThere() {
                getCategory({
                    data: {
                        is_recommend: 3,
                        limit:6
                    }
                }).then(res => {
                    if (res && res[0]) {
                        this.classList = res
                        this.classActivity = res[0].id
                        this.pbThereList = res[0]
                    }

                })
            },
            typeActiveThere(id) {
                this.classActivity = id
                this.pbThereList = this.classList.filter(item => item.id == id)[0]
            },
            getInfoWenFoure() {
                getCategory({
                    data: {
                        is_recommend: 4
                    }
                }).then(res => {
                    this.pbFoureList = res && res.length ? res : []
                })
            },
            getInfoWenFive() {
                getCategory({
                    data: {
                        is_recommend: 5,
                        limit:6
                    }
                }).then(res => {
                    this.pbFiveList = res && res[0] ? res[0] : {
                        article: []
                    }
                })
            },
            // 切换
            typeActive(id, pid, index) {
                this.swiper.slideTo(index)
                this.typeActivity = id
                this.typePid = pid
                this.pbList = this.typeList.filter(item => item.id == id)[0].article
            },
            //跳转文章详情
            tapInfo(wen_id, pid = '', id = '') {
                this.$router.push({
                    path: '/agricultureDetails',
                    query: {
                        pid: pid ? pid : this.id,
                        wen_id: wen_id,
                        type: 1,
                        id: id ? id : this.typeActivity
                    }
                })
            },
            handleJump(id, pid = '') {
                this.$router.push({
                    path: '/agriculturePart',
                    query: {
                        id: id,
                        pid: pid ? pid : this.id
                    }
                });
            },
            tapAinfo() {
                let a = document.createElement('a');
                a.target = '_blank';
                a.href = this.configInfo.report
                a.click()
            },
            // 设置元素高度
            settingHeight(class_name) {
                this.$nextTick(() => {
                    let proportion = 4 / 3
                    let yauns = document.querySelector(class_name)
                    if (yauns) {
                        let width = yauns.offsetWidth
                        let height = (width / proportion) + 'px'
                        yauns.querySelector('img').style.height = height
                    }
                })
                return {}
            },
            onSwiper(swiper) {
                this.swiper = swiper;
            },
            slideChange(swiper) {
                let realIndex = swiper.realIndex
                let arr = this.typeList[realIndex]
                this.typeActivity = arr.id
                this.typePid = arr.pid
                this.pbList = this.typeList[realIndex].article
            },
            stopSwiper() {
                this.swiper.autoplay.stop()
            },
            startSwiper() {
                this.swiper.autoplay.start()
            }
        }
    }
</script>

<style lang="less" scoped>
    .mySwiper {
        height: 100%;
    }

    .region_main_a {
        max-width: 100%;
        margin: 0 auto;
    }

    .hot_topics_text {
        margin: 0 auto !important;

        .topics_text {
            width: 0;
            flex: 1;
        }
    }

    .contents {
        margin: 0 5px;
        padding: 15px 10px 10px 10px;
        width: auto;
        height: auto;
        background: #ffffff;
        cursor: pointer;

        .line {
            width: 24px;
            height: 3px;
            background: #999;
            border-radius: 0px 0px 0px 0px;
            margin-bottom: 10px;
        }

        .title {
            font-size: 18px;
            color: #333333;
            line-height: 30px;
            text-align: left;
            margin-bottom: 10px;
            font-weight: 500;
            -webkit-line-clamp: 1;
        }

        .content {
            color: #999999;
            line-height: 24px;
            text-align: left;
            margin-bottom: 10px;
            -webkit-line-clamp: 3;
        }

        .content_a {
            -webkit-line-clamp: 12;
            height: 288px;
        }

        .times {
            font-size: 15px;
            color: #999;
            line-height: 24px;
            text-align: left;
            margin-bottom: 10px;
        }

        .imgs {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                vertical-align: text-bottom;
            }
        }
    }

    .class_main {
        max-width: 85%;
        margin: 0 auto;
        display: table;
        margin-bottom: 20px;

        .class_box {
            float: left;
            width: calc(50% - 20px);
            margin: 0 10px;
            margin-bottom: 20px;
            background: #ffffff;

            .class_contents {
                display: flex;
                padding: 15px;

                .left {
                    float: left;
                    width: 160px;

                    img {
                        width: 100%;
                        vertical-align: text-bottom;
                    }
                }

                .right {
                    float: left;
                    margin-left: 20px;
                    width: calc(100% - 180px);

                    .title {
                        font-weight: 500;
                        font-size: 18px;
                        color: #333;
                        line-height: 24px;
                        text-align: left;
                        -webkit-line-clamp: 2;
                        margin-bottom: 8px;
                    }

                    .text {
                        font-weight: 500;
                        color: #999;
                        line-height: 22px;
                        text-align: left;
                        -webkit-line-clamp: 2;
                    }
                }

            }
        }
    }

    .brand_main {
        padding-bottom: 40px;
        padding-left: 10px;
        padding-right: 10px;

        .brand_box {
            width: 33.3333333%;
            float: left;
            margin-bottom: 20px;

            .content_box {
                margin: 10px;
                position: relative;

                img {
                    width: 100%;
                }

                .brand_content {
                    position: absolute;
                    left: 0;
                    bottom: -20px;
                    width: calc(90% - 20px);
                    background: #138449;
                    padding: 5px 10px 5px 15px;
                    border-radius: 5px;

                    .brand_title {
                        font-weight: 600;
                        font-size: 18px;
                        color: #fff;
                        line-height: 30px;
                        text-align: left;
                        -webkit-line-clamp: 1;
                    }

                    .brand_text {
                        font-weight: 500;
                        color: #fff;
                        line-height: 24px;
                        text-align: left;
                        -webkit-line-clamp: 2;
                    }
                }
            }
        }
    }

    .introduction {
        width: calc(100% - 40px);
        margin: 0 20px;
        padding-bottom: 50px;

        .sanjiao {
            width: 0;
            height: 0;
            border: 15px solid #fff;
            position: absolute;
            border-right-color: transparent;
            border-bottom-color: transparent;
        }

        .floatl {
            float: left;
            width: 40%;
            display: flex;

            .brief_l {
                width: 50%;

                img {
                    width: 100%;
                }
            }

            .brief_r {
                width: calc(50% - 20px);
                padding: 10px;
                position: relative;
            }

            .brief_la {
                width: calc(50% - 20px);
                padding: 10px;
            }

            .brief_ra {
                width: 50%;

                img {
                    width: 100%;
                }
            }

            .one_sanjiao {
                left: -15px;
                top: 75px;
                transform: rotate(-45deg);
            }
        }

        .floatr {
            float: right;
            width: 20%;
            position: relative;

            .brief_l {
                width: 100%;

                img {
                    width: 100%;
                    height: 180px;
                }
            }

            .brief_r {
                width: calc(100% - 20px);
                padding: 10px;
                position: relative;
            }

            .two_sanjiao {
                top: -19px;
                left: 125px;
                transform: rotate(45deg);
            }


        }

        .floatla {
            .sanjiao {
                transform: rotate(135deg);
                right: -15px;
                top: 80px;
            }
        }

        .brief_title {
            font-weight: 500;
            font-size: 18px;
            color: #333333;
            line-height: 0px;
            text-align: left;
            margin-top: 10px;
        }

        .brief_line {
            width: 40px;
            height: 2px;
            background: #138449;
            border-radius: 0px 0px 0px 0px;
            border: 1px solid #138449;
            margin-top: 20px;
        }

        .brief_content {
            font-weight: 400;
            color: #999;
            line-height: 22px;
            text-align: left;
            -webkit-line-clamp: 4;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .mores {
            color: #138449;
            display: flex;
            justify-content: left;
            align-items: center;

            img {
                width: 20px;
                margin-left: 10px;
            }
        }
    }

    .type_box {
        padding-right: 25px;
        margin-left: 25px;
    }

    .s_i_g_4 {
        .type_main {
            .type_list {
                border-right: 0;
            }

            position: relative;

            .more_main {
                position: absolute;
                right: 10px;
                top: 50%;
                font-size: 14px;
                color: #999;
                cursor: pointer;
            }
        }

    }

    .type_main::-webkit-scrollbar {
        height: 0;
    }

    .title_text_hover:hover {
        .brief_title {
            color: #138449 !important;
        }

        .brief_content {
            color: #138449 !important;
        }
    }

    .phone_box {
        display: none !important;
    }

    .special_subject{
        padding-bottom:40px;
    }

    //横屏
    @media screen and (max-width: 1400px) {
        .contents {
            .content_a {
                -webkit-line-clamp: 11 !important;
                height: 288px;
            }
        }
    }

    //竖屏
    @media screen and (max-width: 1200px) {
        .contents {
            .content_a {
                -webkit-line-clamp: 9 !important;
                height: 216px;
            }
        }
    }

    //手机
    @media screen and (max-width: 575px) {

        .special_subject{
            padding-bottom:20px;
        }

        .brief_l img {
            height: 140px !important;
        }

        .type_main {
            overflow-x: scroll;
            white-space: nowrap;
        }

        .sanjiao {
            display: none;
        }

        .mySwiper {
            display: none;
        }

        .phone_box {
            display: block !important;
        }
    }
</style>