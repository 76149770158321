<template>
    <div class="header_main">
        <div class="header_box">
            <div class="header_top"></div>
            <div class="header_content maxW">
                <div class="header_log cursors" @click="handleJump('/index',1)">
                    <img :src="configInfo.logo" alt=""></div>
                <div class="header_menu">
                    <div :style="moveStyle" class="line">
                        <div class="divS"></div>
                    </div>
                    <div class="menu_list" :class="urlPathname == item.id ? 'activity' : ''" :ref="item.id+''"
                        v-for="(item,index) in menuList" @click="handleJump(item.jumplink,item.id)" :key="index">
                        <span class="cursors">{{ item.title }}</span>
                        <div class="options" v-if="item.child && item.child.length">
                            <div class="options_list" v-for="(items,indexs) in item.child"
                                @click.stop="handleJumpZji(item.id,items)" :key="indexs">
                                <span class="cursors">{{ items.title }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="search_mian">
                        <div><input v-model="keyword" class="input_box" @keyup.enter="searchKeyword"
                                placeholder="搜索文章" /></div>
                        <div class="search_img_box cursors" @click="searchKeyword">
                            <img src="@/assets/images/public/search.png" />
                        </div>
                    </div>
                </div>
                <div class="menu_img" @click="showMenuOpen">
                    <img src="@/assets/images/public/icon_menu.png" />
                </div>
            </div>
        </div>

        <transition name="sidenav">
            <div class="sidenav" v-show="isOpen" @click="showMenuClose">
                <div class="sidenav_main">
                    <div class="close_box">
                        <img src="@/assets/images/public/icon_close.png" />
                    </div>
                    <div class="sidenav_menu">
                        <div class="menu_list" :class="urlPathname == item.jumplink ? 'activity' : ''"
                            v-for="(item,index) in menuList" @click="handleJump(item.jumplink,item.id)" :key="index">
                            {{ item.title }}
                            <div class="options" v-if="item.child && item.child.length">
                                <div class="options_list" :class="urlPathname == items.jumplink ? 'activity' : ''"
                                    v-for="(items,indexs) in item.child" @click.stop="handleJumpZji(item.id,items)"
                                    :key="indexs">{{ items.title }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <div class="seize_seat">
            <!-- 占位 -->
        </div>
    </div>
</template>

<script>
    import {
        getCategory,
        getConfig
    } from '@/api/index'
    export default {
        components: {

        },
        data() {
            return {
                urlPathname: 1,
                isOpen: false,
                moveStyle: {
                    left: 0,
                    width: '0px'
                },
                configInfo: {},
                menuList: [
                    // {title:'首页',refName:'index',pathName:'index'},
                    // {title:'走进农发',refName:'walkIntoAgricultureHair',pathName:'walkIntoAgricultureHair'},
                    // {title:'新闻中心',refName:'newsCenter',pathName:'newsCenter'},
                    // {title:'业务领域',refName:'businessAreas',pathName:'businessAreas'},
                    // {title:'集团品牌',refName:'groupBrand',pathName:'groupBrand'},
                    // {title:'党的建设',refName:'partyBuilding',pathName:'partyBuilding'},
                    // {title:'企业文化',refName:'corporateCulture',pathName:'corporateCulture'},
                    // {
                    //     title:'信息公开',
                    //     refName:'informationDisclosure',
                    //     pathName:'',
                    //     children:[
                    //         {title:'公示公告',pathName:'publicAnnouncement'},
                    //         {title:'联系我们',pathName:'contactUs'}
                    //     ]
                    // }
                ],
                keyword: ''
            }
        },
        created() {
            if (this.$route.query.id && !this.$route.query.pid) {
                this.urlPathname = this.$route.query.id
            } else if (this.$route.query.pid) {
                this.urlPathname = this.$route.query.pid
            }
            this.getCategory()
            this.getConfig()
        },
        // watch: {
        //     $route(to, from) {
        //         this.urlPathname = to.path
        //     }
        // },
        mounted() {},
        methods: {
            getConfig() {
                getConfig().then(res => {
                    this.configInfo = res
                })
            },
            getCategory() {
                getCategory({
                    data: {
                        type: 'tree'
                    }
                }).then(res => {
                    this.menuList = res
                    this.$nextTick(() => {
                        this.handleMoveStyle()
                    })

                })
            },
            handleMoveStyle() {
                let refName = this.urlPathname ? this.urlPathname : ''
                // let infoAll = ['/publicAnnouncement', '/contactUs', '/messagePublic'] //信息公开
                // let infoAllA = ['/agricultureGroup', '/agricultureOrganize', '/agriculturePart',
                //     '/agricultureDetails'
                // ] //走进农发
                // if (infoAll.indexOf(refName) > -1) {
                //     refName = '/informationDisclosure'
                // }
                // if (infoAllA.indexOf(refName) > -1) {
                //     refName = '/walkIntoAgricultureHair'
                // }
                if (this.$refs[refName] && this.$refs[refName][0]) {
                    this.moveStyle.left = `${this.$refs[refName][0].offsetLeft}px`
                    this.moveStyle.width = `${this.$refs[refName][0].clientWidth}px`
                }
            },
            handleJump(pageUrl, id = '') {
                if (pageUrl == '/informationDisclosure') {
                    return false
                }
                this.urlPathname = id
                if (pageUrl) {
                    this.handleMoveStyle()
                    this.showMenuClose()
                    if (pageUrl.indexOf('/index') > -1) {
                        this.$router.push({
                            path: '/'
                        });
                    } else {
                        this.$router.push({
                            path: pageUrl,
                            query: {
                                id
                            }
                        });
                    }
                }

            },
            showMenuOpen() {
                this.isOpen = true
            },
            showMenuClose() {
                this.isOpen = false
            },
            // 跳转文章列表
            handleJumpZji(pid, data) {
                this.urlPathname = pid
                this.handleMoveStyle()
                if (data.type == 'link') {
                    return window.location.href = data.jumplink
                } else {
                    this.$router.push({
                        path: '/waters',
                        query: {
                            id: data.id,
                            pid: pid
                        },
                        replace: true
                    });
                }

            },
            searchKeyword() {
                if (!this.keyword) {
                    return;
                }
                if (this.$route.path == '/search') {
                    this.$emit('searchFun',this.keyword)
                } else {
                    this.$router.replace({
                        path: '/search',
                        query: {
                            keyword: this.keyword
                        },
                    });
                }
            },
        }
    }
</script>

<style lang="less" scoped>
    .header_main {
        background: #ffffff;

        .seize_seat {
            width: 100%;
            height: 80px;
        }
    }

    .search_mian {
        width: 140px;
        display: flex;
        align-items: center;

        .input_box {
            width: 100px;
            height: 32px;
            background: #e6e6e6;
            border: 0;
            border-radius: 4px 0 0 4px;
            padding: 0 5px;
            font-size:16px;
        }

        .input_box:focus {
            outline: none;
        }

        .search_img_box {
            background: #e6e6e6;
            width: 32px;
            height: 32px;
            border-radius: 0 4px 4px 0;
            border-left: 1px #dedede solid;

            img {
                width: 90%;
                margin-top: 1px;
            }
        }
    }

    .header_box {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 80px;
        background: #ffffff;

        .header_top {
            height: 20px;
            width: 100%;
            background: #138449;
        }

        .header_content {
            height: 60px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            position: relative;

            .header_log {
                //width: 180px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #ffffff;

                img {
                    height: 80%;
                }
            }

            .header_menu {
                display: flex;
                padding: 0 20px;
                position: relative;

                .menu_list {
                    margin-right: 20px;
                    position: relative;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 600;

                    .options {
                        position: absolute;
                        text-align: center;
                        top: 60px;
                        left: 50%;
                        transform: translate(-50%, 0);
                        // width: 120px;
                        white-space: nowrap;
                        padding: 10px 17px 0px 17px;
                        display: none;
                        z-index: 2px;
                        color: #333333;
                        background: #ffffff;

                        .options_list {
                            margin-bottom: 15px;
                        }
                    }

                    .options_list:hover {
                        color: #138449;
                    }
                }

                .menu_list:hover {
                    color: #138449;

                    .options {
                        display: block !important;
                    }
                }

                .activity {
                    color: #138449;
                }

                .line {
                    height: 3px;
                    position: absolute;
                    bottom: 0;
                    transition: .5s;
                    width: 100%;

                    .divS {
                        background-color: #138449;
                        width: 70%;
                        height: 100%;
                        margin: 0 auto;
                    }
                }
            }

            .menu_img {
                display: none;
                width: 60px;
                height: 60px;
                text-align: center;

                img {
                    margin-top: 10px;
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }

    @media screen and (max-width: 575px) {

        .header_content {
            max-width: 100% !important;

            .menu_img {
                display: block !important;
            }
        }

        .header_log {
            img {
                height: 60% !important;
            }
        }

        .header_menu {
            display: none !important;
        }
    }


    .sidenav-enter-active,
    .sidenav-leave-active {
        transition: transform 0.3s ease-in-out;
    }

    .sidenav-enter-from,
    .sidenav-leave-to {
        transform: translateX(-100%);
    }

    .sidenav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, .5);
        z-index: 150;

        .sidenav_main {
            width: 70%;
            height: 100%;
            overflow-y: auto;
            background: #ffffff;
            position: relative;

            .close_box {
                width: 20px;
                height: 20px;
                position: absolute;
                top: 0px;
                right: 10px;

                img {
                    width: 100%;
                    height: 100%;
                    margin: 10px 10px 0 0;
                }
            }

            .sidenav_menu {
                margin-left: 20px;
                margin-top: 10px;

                .menu_list {
                    font-weight: 600;
                    padding-left: 15px;
                    margin-bottom: 10px;
                    font-size: 15px;
                    border-left: 5px solid #ffffff;

                    .options {
                        margin-left: 20px;
                        margin-top: 10px;

                        .options_list {
                            padding-left: 12px;
                            margin-bottom: 10px;
                        }
                    }
                }

                .activity {
                    color: #138449;
                    border-left: 5px solid #138449;
                }
            }
        }
    }
</style>