<template>
    <div class="Loading">
        <div className="roots">
            <img src="@/assets/images/public/loading.gif" class="img_gif" alt="">
        </div>
    </div>
</template>

<script>

</script>

<style lang="less" scoped>
    .img_gif {
        width: 400px;
        height: auto;
    }

    @media screen and (max-width: 575px) {
        .img_gif {
            width: 200px !important;
        }
    }

    .Loading {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: 100;

        .roots {
            --color: orange;
            --lineColor: rgba(102, 163, 224, 0.2);
        }

        .roots {
            width: 100%;
            height: 80vh;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        }

        section {
            position: relative;
            width: 200px;
            height: 200px;
        }

        section::before {
            content: "";
            position: absolute;
            height: 10px;
            width: 10px;
            border-radius: 100%;
            border-top: 1px solid orange;
            top: 50%;
            left: 50%;
            margin-top: -5px;
            margin-left: -5px;
            animation: turn 1s infinite linear;
            filter: drop-shadow(0 0 2px var(--color)) drop-shadow(0 0 5px var(--color)) drop-shadow(0 0 10px var(--color)) drop-shadow(0 0 20px var(--color));
        }

        .box,
        .box::after,
        .box::before {
            border: 2px solid var(--lineColor);
            border-left: 2px solid var(--color);
            border-right: 2px solid var(--color);
            border-radius: 50%;
        }

        .box::after,
        .box::before {
            position: absolute;
            content: "";
            left: 50%;
            top: 50%;
        }

        .box {
            width: 200px;
            height: 200px;
            position: relative;
            animation: turn 1s linear infinite;
            transform-origin: 50% 50%;
        }

        .box::before {
            width: 180px;
            height: 180px;
            margin-top: -90px;
            margin-left: -90px;
            animation: turn2 1.25s linear infinite;
        }

        .box::after {
            width: 160px;
            height: 160px;
            margin-top: -80px;
            margin-left: -80px;
            animation: turn 1.5s linear infinite;
        }

        .box-circle,
        .box-circle1,
        .box-circle2 {
            border: 2px solid var(--color);
            opacity: 0.9;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform-origin: 50% 50%;
            transform: translate(-50%, -50%);
            width: 100px;
            height: 100px;
            animation: rotate 3s linear infinite;
        }

        .box-circle {
            animation-delay: 0.2s;
        }

        .box-circle1 {
            animation-delay: 1.2s;
        }

        .box-circle2 {
            animation-delay: 2.2s;
        }

        @keyframes turn {
            100% {
                transform: rotateZ(-1turn);
            }
        }

        @keyframes turn2 {
            100% {
                transform: rotateZ(1turn);
            }
        }

        @keyframes rotate {
            100% {
                border: none;
                border-top: 2px solid var(--color);
                border-bottom: 2px solid var(--color);
                transform: translate(-50%, -50%) rotate3d(0.5, 0.5, 0.5, -720deg);
            }
        }
    }
</style>