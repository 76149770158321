import {
    createRouter,
    createWebHistory
} from 'vue-router'
import Layout from '@/layout/Layout.vue'

import indexPage from '../views/index.vue'

const routes = [
    {
        path: '/',
        name: 'index',
        component: Layout,
        redirect: '/',
        children: [
            {
                path: '/',
                component: indexPage,
                meta: {
                    title: '首页'
                },
            },
            {
                path: 'index',
                component: indexPage,
                meta: {
                    title: '首页'
                },
            },
            {
                path: 'walkIntoAgricultureHair',
                component: () => import('../views/agricultureHair.vue'),
                meta: {
                    title: '走进农发'
                },
            },
            {
                path: 'agricultureGroup',
                component: () => import('../views/agricultureGroup.vue'),
                meta: {
                    title: '集团简介-走进农发'
                },
            },
            {
                path: 'agricultureOrganize',
                component: () => import('../views/agricultureOrganize.vue'),
                meta: {
                    title: '组织架构-走进农发'
                },
            },
            {
                path: 'agriculturePart',
                component: () => import('../views/agriculturePart.vue')
            },
            {
                path: 'agricultureDetails',
                component: () => import('../views/agricultureDetails.vue')
            },
            {
                path: 'newsCenter',
                component: () => import('../views/newsCenter.vue'),
                meta: {
                    title: '新闻中心'
                },
            },
            {
                path: 'businessAreas',
                component: () => import('../views/businessAreas.vue'),
                meta: {
                    title: '业务领域'
                },
            },
            {
                path: 'groupBrand',
                component: () => import('../views/groupBrand.vue'),
                meta: {
                    title: '集团品牌'
                },
            },
            {
                path: 'partyBuilding',
                component: () => import('../views/partyBuilding.vue'),
                meta: {
                    title: '党的建设'
                },
            },
            {
                path: 'corporateCulture',
                component: () => import('../views/corporateCulture.vue'),
                meta: {
                    title: '企业文化'
                },
            },
            {
                path: 'publicAnnouncement',
                component: () => import('../views/publicAnnouncement.vue'),
                meta: {
                    title: '公示公告'
                },
            },
            {
                path: 'messagePublic',
                component: () => import('../views/messagePublic.vue'),
                meta: {
                    title: '检举控告信息公示'
                },
            },
            {
                path: 'contactUs',
                component: () => import('../views/contactUs.vue'),
                meta: {
                    title: '联系我们'
                },
            },
            {
                path: '/waters',
                name: 'waters',
                component: () => import('../views/waters.vue'),
            },
            {
                path: '/search',
                name: 'search',
                component: () => import('../views/search.vue'),
            }
        ],

    },
    {
        path: '/party',
        name: 'party',
        component: () => import('../views/party.vue'),
    },
    {
        path: '/spirit',
        name: 'spirit',
        component: () => import('../views/spirit.vue'),
    }
]

const router = createRouter({
    //history: createWebHistory(process.env.BASE_URL),
    history: createWebHistory(),
    routes,
    scrollBehavior(savedPosition) {
        window.scrollTo(savedPosition.x, savedPosition.y)
    }
})

export default router