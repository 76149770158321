<template>
    <div class="subject" :class="subjectBg">
        <div class="maxW">
            <div class="subject_main">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {
        
    },
    props: {
        subjectBg: {
            type:String,
            default: ''
        },
    },
    data() {
        return {
            
        }
    },
    created() {

    },
    mounted() {
        
    },
    methods: {
        
    }
}
</script>

<style lang="less" scoped>
    
</style>