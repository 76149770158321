<template>
    <div class="banner_main">
        <swiper class="mySwiper" :autoplay="autoplayData" :loop="true" @swiper="onSwiper" @slideChange="onSlideChange">
            <swiper-slide v-for="(item,index) in bannerList" :key="index">
                <img v-if="item.imgUrl" :src="item.imgUrl"/>
            </swiper-slide>
        </swiper>
        <div class="banner_bottom" :class="bannerList[realIndex-1]?.title ? 'banner_bottom_bg' : ''">
            <div class="explain" v-html="bannerList[realIndex-1]?.title"></div>
            <div class="swiper_op" v-if="bannerList.length > 1">
                <div class="nums"><span>{{ realIndex }}</span>/{{ bannerList.length }}</div>
                <div class="buts">
                    <span class="buts_l cursors" @click="swiperPage(1)">
                        <img src="@/assets/images/public/icon_prev.png"/>
                    </span>
                    <span class="buts_c cursors" @click="swiperPlay()">
                        <img v-if="autoplayStatus" src="@/assets/images/public/icon_suspend.png"/>
                        <img v-else src="@/assets/images/public/icon_play.png"/>
                    </span>
                    <span class="buts_r cursors" @click="swiperPage(2)">
                        <img src="@/assets/images/public/icon_next.png"/>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//轮播
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import SwiperCore, { Autoplay } from "swiper";
SwiperCore.use([Autoplay]);

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        listData: {
            type: Array,
            default: []
        },
        // bannerExplain:{
        //     type:String,
        //     default: ''
        // }
    },
    data() {
        return {
            swiper:'',
            autoplayStatus:true,
            bannerList:[],
            autoplayData:{
                delay: 3000,
                disableOnInteraction: false
            },
            realIndex:1,
        }
    },
    watch: {
        listData: {
            handler(newValue, oldValue) {
                this.bannerList = newValue
            },
            deep: true,
        },
    },
    created() {
        this.bannerList = this.listData
    },
    mounted() {

    },
    methods: {
        onSwiper(swiper) {
            this.swiper = swiper;
        },
        swiperPage(s){
            if(s == 1){
                this.swiper.slidePrev()
            }else{
                this.swiper.slideNext()
            }
        },
        swiperPlay(){
            if(this.autoplayStatus){
                this.swiper.autoplay.stop()
            }else{
                this.swiper.autoplay.start()
            }
            this.autoplayStatus = !this.autoplayStatus
        },
        onSlideChange(e){
            this.realIndex = e.realIndex + 1
        }
    }
}
</script>

<style lang="less" scoped>
.banner_main{
    width:100%;
    // height:calc(100vh - 120px);
    min-height: 300px;
    position: relative;

    img{
        width:100%;
        height:100%;
        vertical-align:text-bottom;
    }
}

.banner_bottom{
    position: absolute;
    left:0;
    bottom:0;
    width:calc(100% - 30px);
    padding:20px 0 20px 30px;
    z-index:15;
    display:flex;
    justify-content: space-between;

    .explain{
        font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
        font-weight: bold;
        font-size: 22px;
        color: #FFFFFF;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
}
.banner_bottom_bg{
    background: linear-gradient( 270deg, rgba(255,255,255,0) 0%, #138449 100%);
}

.mySwiper{
    width:100%;
    height:100%;
}

.swiper_op{
    width:150px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    border-bottom:5px rgba(255,255,255,0.5) solid;
    margin-right:60px;

    .nums{
        color:#ffffff;
        font-size:16px;

        span{
            font-size:22px;
        }
    }

    .buts{
        .buts_c{
            margin:0 10px;
        }

        img{
            width:16px;
        }
    }
}

//横屏
@media screen and (max-width: 1400px){
    // .banner_main{
    //     height:653px !important;
    // }
}

//竖屏
@media screen and (max-width: 1200px){
    // .banner_main{
    //     height:495px !important;
    // }
}

//手机
@media screen and (max-width: 575px){
    // .banner_main{
    //     height:188px !important;
    // }
    .banner_main{
        min-height: 100px;
    }
    .banner_bottom{
        padding:10px 0 10px 15px !important;
        width:100%;

        .explain{
            font-size: 16px !important;
        }

        .swiper_op{
            display:none !important;
        }
    }
}
</style>