import axios from 'axios'
import config from './config'
import store from '../store/index'
import {
    nextTick
} from 'vue';
//请求的api
const current_api = {}
let loginOutStatus = true

function req({
    url = '',
    data = {},
    type = 'post',
    loading = true,
    toast = false,
    mode = 'api'
}) {
    // if (loading) {
    //     store.state.loading_state = true;
    // }

    return new Promise((resolve, reject) => {
        //当前接口是否正在请求
        // var CancelToken = axios.CancelToken;
        // var source = CancelToken.source();
        // if (current_api[url]) {
        //     current_api[url].source.cancel()
        //     resolve()
        // }else{
        //     current_api[url] = {source}
        // }

        const current_api_url = url

        // if (process.env.NODE_ENV === 'development') { // 本地代理处理
        //     url = '/apis' + current_api_url
        // } else {
        url = config.host + url;
        // }
        let headers = {
            'Content-Type': 'application/json',
            // 'token': localStorage.getItem('token')
        }

        axios({
            method: type,
            url: url,
            headers,
            data: data,
        }).then(res => {
            //删除不可重复提交的地址
            delete current_api[current_api_url]
            //处理返回数据
            res = res.data
            if (res.code == 1) {
                resolve(res.data)
                // if (toast) {
                //     message.success(res.info || res.msg,2)
                // }
            } else if (res.code == -2) {
                localStorage.setItem('token', '')
                if (loginOutStatus) {
                    loginOutStatus = false
                    Modal.warning({
                        title: '温馨提示',
                        content: '账号已失效，请重新登录~',
                        okText: '去登录',
                        onOk() {
                            loginOutStatus = true
                            location.href = '/login'
                        }
                    });
                }
            } else if (res.code == 0) {
                // message.error(res.info || res.msg, 2)
                alert(res.info || res.msg)
                reject(res)
            }
        }).catch(e => {
            //删除不可重复提交的地址
            delete current_api[current_api_url]
            // Toast("网络错误，请稍后重试")
            reject(e)
        }).finally(() => {
            // if (loading) {
            //     nextTick().then(() => {
            //         store.state.loading_state = false;
            //     })
            // }
            //删除不可重复提交的地址
            delete current_api[current_api_url]
        })
    })


}
let requestingCount = 0;
 
const handleRequestLoading = () => {
  if (!requestingCount)  store.state.loading_state = true;
  requestingCount++
}
const handleResponseLoading = () => {
  requestingCount--
  if (!requestingCount)  store.state.loading_state = false;
}
 
axios.interceptors.request.use(config => {
    handleRequestLoading()
    return config
  },(error) => {
    handleResponseLoading()
    return Promise.reject(error);
  })
 
  axios.interceptors.response.use(response => {
    handleResponseLoading()
    return response
  }, error => {
    handleResponseLoading()
    return Promise.reject(error);
  })
export default req;