<template>
  <Loading v-if="this.$store.state.loading_state"></Loading>
  <router-view />
</template>
<script>
import Loading from '@/components/loading.vue'
export default {
  components:{
    Loading
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}
</script>