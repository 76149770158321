<template>
    <div class="footer_main">
        <div class="friendship_link_main">
            <div class="friendship_box maxW">
                <div class="friendship_link">
                    <img src="@/assets/images/public/icon_link.png" />
                    友情链接
                </div>
                <div class="select_main cursors" v-for="(item,index) in list" :key="index">
                    {{item.group}}
                    <img src="@/assets/images/public/icon_select.png" v-if="item.links && item.links.length" />
                    <div class="options" v-if="item.links && item.links.length">
                        <div @click="tapUrl(items)" class="options_list" v-for="(items,indexs) in item.links"
                            :key="indexs">
                            <span class="cursors">{{ items.title }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="footer_box maxW">
                <div class="footer_logo">
                    <div>
                        <img class="cursors" :src="configInfo.sub_logo" alt="" @click="openUrl('/')">
                    </div>
                </div>
                <div class="footer_contact">
                    <div><img src="@/assets/images/public/icon_address.png" />地址：{{ configInfo.address }}</div>
                    <!-- <div><img src="@/assets/images/public/icon_email.png" />邮箱：{{ configInfo.email }}</div> -->
                    <div><img src="@/assets/images/public/icon_phone.png" />电话：{{ configInfo.phone }}</div>
                </div>
                <div class="footer_code">
                    <div class="code_box">
                        <img :src="configInfo.qrcode" />
                    </div>
                    <div>微信公众号</div>
                </div>
            </div>
            <div class="copyright_main">
                <div class="copyright_web">
                    <!-- <div class="web_left">
                        <div class="web_content cursors" @click="openUrl('/')">法律声明</div>
                        <div class="web_content cursors" @click="openUrl('/')">反馈留言</div>
                        <div class="web_content cursors" @click="openUrl('/')">网站地图</div>
                    </div> -->
                    <div class="web_right">
                        <div class="web_content" style="border-right:0;" v-if="configInfo.copyright">
                            {{ configInfo.copyright }}</div>
                    </div>
                </div>
                <div class="copyright_info">
                    <!-- <div class="copyright_content" v-if="configInfo.address">地址：{{ configInfo.address }}</div> -->
                    <div class="copyright_content" v-if="configInfo.phone">传真：{{ configInfo.fax }}</div>
                    <div class="copyright_content" v-if="configInfo.beian_icp">
                        <img src="@/assets/images/public/icon_beian.png" />
                        {{ configInfo.beian_icp }}
                    </div>
                    <div class="copyright_content cursors" v-if="configInfo.beian_mps"
                        @click="openUrl('https://beian.miit.gov.cn/#/Integrated/index')">
                        {{ configInfo.beian_mps }}
                    </div>
                    <div class="copyright_content" v-if="configInfo.support">技术支持：{{ configInfo.support }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getConfig,
        getLinks
    } from '@/api/index.js'
    export default {
        components: {

        },
        data() {
            return {
                configInfo: {},
                list: []
            }
        },
        created() {
            this.getConfig()
            this.getLinks()
        },
        mounted() {

        },
        methods: {
            getConfig() {
                getConfig().then(res => {
                    this.configInfo = res
                })
            },
            // 获取友情链接
            getLinks() {
                getLinks().then(res => {
                    this.list = res
                })
            },
            tapUrl(info) {
                if (info.jumplink) {
                    if (info.target == '_blank') {
                        window.open(info.jumplink, info.target)
                    } else {
                        window.location.href = info.jumplink
                    }
                }
            },
            openUrl(url) {
                if (url == '/') {
                    this.$router.push({
                        path: url
                    })
                } else {
                    window.location.href = url
                }

            }
        }
    }
</script>

<style lang="less" scoped>
    .footer_main {
        width: 100%;
        margin: 0 auto;
        background: #1c8855;

        .friendship_link_main {
            height: 40px;
            width: 100%;
            background: #238C54;

            .friendship_box {
                display: flex;
                width: 100%;
                color: #ffffff;

                .friendship_link {
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 60px;
                    margin-left: 100px;

                    img {
                        width: 28px;
                        height: 28px;
                        margin-right: 8px;
                    }
                }

                .select_main {
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 60px;
                    position: relative;

                    img {
                        width: 12px;
                        height: 12px;
                        margin-left: 10px;
                    }

                    .options {
                        position: absolute;
                        text-align: center;
                        top: 40px;
                        left: 50%;
                        transform: translate(-50%, 0);
                        flex-direction: column;
                        // width: 130px;
                        // display: inline-block;
                        white-space: nowrap;
                        padding: 5px 17px;
                        display: none;
                        z-index: 2px;
                        color: #333333;
                        max-height: 150px;
                        overflow-y: scroll;
                        background: #ffffff;

                        .options_list {
                            margin-bottom: 10px;
                        }
                    }

                    .options_list:hover {
                        color: #138449;
                    }

                }

                .select_main:hover {

                    .options {
                        display: block !important;
                    }
                }

            }
        }

        .footer {
            background: url('@/assets/images/backdrop/footer.png');
            background-position: center;
            /* 确保背景图在容器中居中 */
            background-size: cover;
            /* 背景图覆盖整个容器 */
            background-clip: content-box;
            /* 背景剪裁到内容区域 */

            .footer_box {
                color: #ffffff;
                display: flex;
                width: 100%;
                padding: 60px 0 15px 0;

                .footer_logo {
                    flex: 5;
                    padding-left: 40px;
                    img{
                        max-width: 154px;
                    }
                }

                .footer_contact {
                    flex: 3.2;

                    div {
                        display: flex;
                        text-align: left;
                        margin-bottom: 15px;

                        img {
                            width: 16px;
                            height: 16px;
                            margin-right: 7px;
                            margin-top: 1px;
                        }
                    }
                }

                .footer_code {
                    flex: 1.8;
                    text-align: center;

                    .code_box {
                        width: 75px;
                        height: 75px;
                        margin: 0 auto;
                        background: #ffffff;
                        margin-bottom: 10px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    .copyright_main {
        font-size: 14px;
        color: #ffffff;
        padding: 15px;

        .copyright_web {
            display: flex;
            justify-content: center;
            align-items: center;

            .web_left {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .web_content {
                padding: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 10px;
                border-right: 1px #ffffff solid;
            }
        }

        .copyright_info {
            display: flex;
            justify-content: center;
            align-items: center;

            .copyright_content {
                padding: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 10px;
                border-right: 1px #ffffff solid;

                img {
                    width: 15px;
                    height: 15px;
                }
            }
        }

        .copyright_content:last-child {
            border-right: 0;
        }
    }

    @media screen and (max-width: 575px) {
        .friendship_link_main {
            display: none !important;
        }

        .footer_box {
            padding: 40px 0 15px 0 !important;
            display: block !important;

            .footer_logo,
            .footer_contact,
            .footer_code {
                margin: 0 auto;
                width: 90%;
            }

            .footer_contact,
            .footer_code {
                margin-top: 30px;
            }
        }

        .copyright_web {
            display: block !important;

            .web_content:last-child {
                border-right: 0 !important;
            }

            .web_right {
                width: 100%;
                display: block !important;
            }
        }

        .copyright_info {
            display: block !important;
            width: 100%;
            justify-content: left !important;

            .copyright_content {
                display: block !important;
                width: 100%;
                justify-content: left !important;
                border-right: 0 !important;
            }
        }
    }
</style>