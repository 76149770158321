import {
    nextTick
} from 'vue'
export default {

    // 设置元素高度
    settingHeight(class_name, partStatus = null) {
        nextTick(() => {
            let proportion = partStatus ? 16 / 9 : 4 / 3
            let yauns = document.querySelector(class_name)
            if (yauns) {
                let width = yauns.offsetWidth
                let height = (width / proportion) + 'px'
                yauns.querySelector('img').style.height = height
            }
        })
        return {}
    },

    //设置网站头部信息
    setHeadData(data) {
        document.title = data.title
        let head = document.getElementsByTagName('head'); // 创建head标签
        //网站关建词
        var metaKey = document.querySelector("meta[name*='keywords']") || document.createElement('meta');
        metaKey.name = "keywords";
        metaKey.content = data.keyword;
        // head[0].appendChild(metaKey)
        //网站描述
        var metaDes = document.querySelector("meta[name*='description']") || document.createElement('meta');
        metaDes.name = "description";
        metaDes.content = data.description;
        // head[0].appendChild(metaDes)
        //网站图标
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.href = data.favicon
        // head[0].appendChild(link);
    }
}