import req from "../methods/req"

//获取网站栏目
export const getCategory = function (data) {
    return req({
        url: '/cms/api/base/getCategory',
        ...data
    })
}

//获取网站配置
export const getConfig = function (data) {
    return req({
        url: '/cms/api.base/getConfig',
        ...data
    })
}

//获取文章列表
export const getList = function (data) {
    return req({
        url: '/cms/api/index/getList',
        ...data
    })
}
//获取文章详情
export const getDetail = function (data) {
    return req({
        url: '/cms/api/index/getDetail',
        ...data
    })
}

//获取友情链接
export const getLinks = function (data) {
    return req({
        url: '/cms/api.base/getLinks',
        ...data
    })
}

//首页轮播图
export const getBanner = function (data) {
    return req({
        url: '/cms/api/base/getBanner',
        ...data
    })
}

//点赞接口
export const setLikes = function (data) {
    return req({
        url: '/cms/api/base/setLikes',
        ...data
    })
}


